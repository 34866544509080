import * as React from 'react';
import { StyledEngineProvider } from '@material-ui/core/styles';
import Layout from '../components/Layout';

export default function Index() {
  return (
    <StyledEngineProvider injectFirst>
      <Layout home>
      </Layout>
    </StyledEngineProvider>
  );
}
